#console {
    padding-top: 5.2em;
}

#pro {
    margin-top: 4%;
    margin-left: 4%;
    width: 25%;
}

#msg_bvn {
    margin-top: 9%;
    margin-left: 10%;
    color: black;
    font-size: 2em;
}

#c {
    cursor: pointer;
    margin-top: 15%;
    width: 50%;
    margin-left: 6%;
}

#r {
    cursor: pointer;
    margin-top: 15%;
    width: 50%;
    margin-left: 6%;
}

#f {
    cursor: pointer;
    margin-top: 15%;
    width: 50%;
    margin-left: 6%;
}

#d {
    cursor: pointer;
    margin-top: 5%;
    width: 50%;
    margin-left: 6%;
}

#pos-c {
    margin-top: 5%;
    margin-left: 9%;
}

#pos-d {
    margin-top: 5%;
    margin-left: 9%;
}

#div_pro_txt {
    display: inline-block;
    vertical-align: top;
    width: 40%;
}

#d_txt {
    margin-top: 3.5%;
    width: 50%;
}

#c_txt {
    width: 50%;
    margin-top: 6%;
}

#div_d {
    display: flex;
}

#div_c {
    display: flex;
}

#title_d {
    color: black;
    font-weight: bold;
    font-size: 1.3em;
}

#title_c {
    color: black;
    font-weight: bold;
    font-size: 1.3em;
}

#par_d {
    margin-top: -7%;
    font-size: 0.9em;
    width: 120%;
}

#par_c {
    margin-top: -7%;
    font-size: 0.9em;
    width: 120%;
}

#logo_hauts {
    vertical-align: top;
    margin-top: 5%;
    margin-left: 5%;
    width: 12%;
}

#logo_sipperec {
    vertical-align: top;
    margin-top: 5%;
    margin-left: 5%;
    width: 12%;
}

#logo_sipperec2 {
    vertical-align: top;
    margin-top: 5%;
    margin-left: 5%;
    width: 62%;
}