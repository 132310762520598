img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
iframe,
object,
embed,
video {
    max-width: 100%;
}

@media only screen and (max-width: 2560px) and (max-height: 1600px) {
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 46%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2.7em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 2560px) and (max-height: 1440px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 22%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 60px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 5.4vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 6.3vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 7.9vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 40px 45px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 70px;
        cursor: pointer;
        font-size: 1.5rem;
    }
    #forget_title1 {
        padding: 25px;
        font-size: 2.3em;
        height: 10%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 25px;
        margin-bottom: 3%;
        font-size: 2.3em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 20px 20px;
        padding-left: 60px;
        border-radius: 30px;
        line-height: 50px;
        font-size: 1.2em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.9%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.9%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 1rem;
        height: 60px;
    }
    td {
        padding-top: 0.9%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.9%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 60px;
        background-color: #EEEEEE;
        font-size: 1rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 1.5em;
        color: green
    }
    #cons {
        font-size: 1.5em;
        color: red;
    }
    #m_mdp {
        font-size: 1.5em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.4%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.4%;
        cursor: pointer;
    }
    #pr,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #prp,
    #pr10 {
        font-size: 1.5em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 90px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 13%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 1.5em;
        background-color: #f5f6f8;
        line-height: 60px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 1.5em;
        line-height: 60px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 1.5em;
        line-height: 60px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 1.5em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 60px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 55px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        margin-top: 2%;
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        margin-top: 1.7%;
        width: 16%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: 5%;
        font-size: 1.6em;
        margin-left: 10%;
        margin-bottom: 147%;
    }
    #etps-p {
        font-size: 1.6em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1.6em;
        margin-left: 15%;
        margin-top: 7%;
    }
    #pr9,
    #pr10 {
        margin-top: 10%;
        margin-bottom: 5.5%;
    }
    #pr5 {
        margin-top: 18%;
        margin-right: 6%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 6%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 4.5%;
    }
    #pr {
        margin-top: 10%;
    }
    #pr2 {
        margin-left: 7.5%;
    }
    /* Left */
    #realised {
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.8%;
        margin-right: 11%;
        width: 14%;
    }
    #devis_p {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 32px 32px;
        line-height: 70px;
        padding-left: 29.5%;
        font-size: 1.5rem;
        width: 70.8%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 32px 32px;
        line-height: 70px;
        padding-left: 28%;
        font-size: 1.5rem;
        width: 70.8%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 70px;
        padding-left: 8%;
        width: 92%;
        font-size: 1.5rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1.2%;
        margin-left: 14%;
        font-size: 3.3rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 79.5%;
        width: 14.5%;
        height: 162px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 85%;
        background-color: white;
        font-size: 0.6em;
        line-height: 350%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 85%;
        text-align: left;
        font-size: 0.6em;
        line-height: 350%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 85%;
        background-color: white;
        font-size: 0.6em;
        line-height: 350%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 85%;
        background-color: #141B4D;
        font-size: 0.6em;
        line-height: 350%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.8%;
        padding-top: 0.3%;
        margin-left: 80%;
        width: 15.5%;
        font-size: 1.5rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 40px;
        background-position: 13px 0;
        padding-left: 70px;
        display: block;
        text-align: left;
    }
    /* Accueil */
    #title_d {
        margin-left: 1%;
        color: black;
        font-weight: bold;
        font-size: 1.8em;
    }
    #title_c {
        margin-left: 1%;
        color: black;
        font-weight: bold;
        font-size: 1.8em;
        margin-top: 2%;
    }
    #par_d {
        margin-left: 1%;
        margin-top: -7%;
        font-size: 1.2em;
        width: 100%;
    }
    #par_c {
        margin-left: 1%;
        margin-top: -7%;
        font-size: 1.2em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 35%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 2.5em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 70%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 70%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 70%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 70%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 47%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2.7em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 1200px) {
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.5rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 7vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 20px 25px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 50px;
        cursor: pointer;
        font-size: 1rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.7em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.7em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 1em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.7rem;
        height: 40px;
    }
    td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.7rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 1em;
        color: green
    }
    #cons {
        font-size: 1em;
        color: red;
    }
    #m_mdp {
        font-size: 1em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.2%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.2%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 1em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 70px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 1em;
        line-height: 40px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 1em;
        line-height: 40px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 1em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 40px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 40px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: 5%;
        font-size: 1.2em;
        margin-left: 10%;
        margin-bottom: 140%;
    }
    #etps-p {
        font-size: 1.2em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1.2em;
        margin-left: 15%;
        margin-top: 7%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 3.5%;
    }
    #pr5 {
        margin-top: 18%;
        margin-right: 6%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 9.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1%;
    }
    #pr {
        margin-top: 4%;
    }
    #pr2 {
        margin-left: 9.5%;
    }
    /* Left */
    #realised {
        font-size: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 20px 20px;
        line-height: 50px;
        padding-left: 28%;
        font-size: 1.1rem;
        width: 72%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 20px 20px;
        line-height: 50px;
        padding-left: 28%;
        font-size: 1.1rem;
        width: 72%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1.2%;
        margin-left: 14%;
        font-size: 2.2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 82%;
        background-color: white;
        font-size: 0.5em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 82%;
        text-align: left;
        font-size: 0.5em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 82%;
        background-color: white;
        font-size: 0.5em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 82%;
        background-color: #141B4D;
        font-size: 0.5em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 15.5%;
        height: 117px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.8%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.3rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 30px;
        background-position: 13px 2px;
        padding-left: 60px;
        display: block;
        width: 290px;
        text-align: left;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1.2em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1.2em;
        margin-top: -2%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.9em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.9em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 30%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 50%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 50%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2.3em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 1080px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 18%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.5rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 7vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 20px 25px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 50px;
        cursor: pointer;
        font-size: 1rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.7em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.7em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 1em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.7rem;
        height: 40px;
    }
    td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.7rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 1em;
        color: green;
    }
    #cons {
        font-size: 1em;
        color: red;
    }
    #m_mdp {
        font-size: 1em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.2%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.2%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 1em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 70px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 1em;
        background-color: #f5f6f8;
        line-height: 40px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 1em;
        line-height: 40px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 1em;
        line-height: 40px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 1em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 40px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 40px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: 5%;
        font-size: 1.2em;
        margin-left: 10%;
        margin-bottom: 123%;
    }
    #etps-p {
        font-size: 1.2em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1.2em;
        margin-left: 10%;
        margin-top: 7%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 3.5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 6%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 9.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1%;
    }
    #pr {
        margin-top: 4%;
    }
    #pr2 {
        margin-left: 9.5%;
    }
    /* Left */
    #realised {
        font-size: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 20px 20px;
        line-height: 50px;
        padding-left: 28%;
        font-size: 1.1rem;
        width: 72%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 20px 20px;
        line-height: 50px;
        padding-left: 28%;
        font-size: 1.1rem;
        width: 72%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 1.1rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1.2%;
        margin-left: 14%;
        font-size: 2.2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 15.5%;
        height: 117px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 82%;
        width: 82%;
        background-color: white;
        font-size: 0.5em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 82%;
        text-align: left;
        font-size: 0.5em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 82%;
        background-color: white;
        font-size: 0.5em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 82%;
        background-color: #141B4D;
        font-size: 0.5em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.8%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.3rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 30px;
        background-position: 13px 2px;
        padding-left: 60px;
        display: block;
        text-align: left;
        width: 290px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1.2em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1.2em;
        margin-top: -2%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.9em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.9em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 30%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 50%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 50%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5.5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 39.5%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2.3em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1680px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 18%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.4vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 6.7vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .8rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.2em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.2em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.8em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.6rem;
        height: 40px;
    }
    td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.6rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.8em;
        color: green;
    }
    #cons {
        font-size: 0.8em;
        color: red;
    }
    #m_mdp {
        font-size: 0.8em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.8em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.8em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.8em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 1em;
        margin-left: 10%;
        margin-bottom: 128%;
    }
    #etps-p {
        font-size: 1em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 9.8%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 11.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: -0.30%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 11%;
    }
    /* Left */
    #realised {
        font-size: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 98px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 79%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 79%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.1rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 25px;
        background-position: 13px 2px;
        padding-left: 55px;
        display: block;
        text-align: left;
        width: 218px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 28%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 1.4em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 41%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1600px) and (max-height: 1200px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 18%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.4vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 7.6vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .8rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.2em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.2em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.8em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.6rem;
        height: 40px;
    }
    td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.6rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.8em;
        color: green;
    }
    #cons {
        font-size: 0.8em;
        color: red;
    }
    #m_mdp {
        font-size: 0.8em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.8em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.8em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.8em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 1em;
        margin-left: 10%;
        margin-bottom: 128%;
    }
    #etps-p {
        font-size: 1em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 9.8%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 11.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: -0.30%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 11%;
    }
    /* Left */
    #realised {
        font-size: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 98px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 79%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 79%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.1rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 25px;
        background-position: 13px 2px;
        padding-left: 55px;
        display: block;
        text-align: left;
        width: 218px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 28%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 1.4em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 39%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1600px) and (max-height: 900px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 16%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 8.2vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .8rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.2em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.2em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.8em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.6rem;
        height: 40px;
    }
    td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.6rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.8em;
        color: green;
    }
    #cons {
        font-size: 0.8em;
        color: red;
    }
    #m_mdp {
        font-size: 0.8em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.8em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.8em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.8em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 1em;
        margin-left: 10%;
        margin-bottom: 135%;
    }
    #etps-p {
        font-size: 1em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 8.2%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 10.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: -0.30%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 11%;
    }
    /* Left */
    #realised {
        font-size: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 98px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 79%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 79%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.1rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 25px;
        background-position: 13px 2px;
        padding-left: 55px;
        display: block;
        text-align: left;
        width: 218px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 28%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 1.4em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_button {
        font-size: 1em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 40%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.8em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.3em;
    }
    #link_mdp {
        font-size: 1em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1550px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 16%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 8.2vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .8rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.2em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.2em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.8em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.6rem;
        height: 40px;
    }
    td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.6rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.8em;
        color: green;
    }
    #cons {
        font-size: 0.8em;
        color: red;
    }
    #m_mdp {
        font-size: 0.8em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.8em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.8em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.8em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 1em;
        margin-left: 10%;
        margin-bottom: 135%;
    }
    #etps-p {
        font-size: 1em;
        margin-top: -20%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 4%;
        margin-right: 7%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 10.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: -0.30%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 11%;
    }
    /* Left */
    #realised {
        font-size: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 98px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 79%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 79%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 79%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1.1rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 25px;
        background-position: 13px 2px;
        padding-left: 55px;
        display: block;
        text-align: left;
        width: 218px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 28%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 1.4em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #incorrect {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect2 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #incorrect3 {
        color: #B22222;
        font-weight: bold;
        font-size: 1em;
    }
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 6% 60%;
        background-size: 60px 75px;
        padding-left: 75px;
        border-radius: 25px;
        line-height: 50px;
        font-size: 1.3em;
        width: 35%;
    }
    #form_button {
        font-size: 1.2em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1.2em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 25px;
        line-height: 50px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 37%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 2.3em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.9em;
    }
    #link_mdp {
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1440px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 16%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1.2rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 8.2vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .8rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.2em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.2em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.8em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.6rem;
        height: 40px;
    }
    td {
        padding-top: 0.8%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.8%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.6rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.8em;
        color: green;
    }
    #cons {
        font-size: 0.8em;
        color: red;
    }
    #m_mdp {
        font-size: 0.8em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.3%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: -0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.8em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.8em;
        background-color: #f5f6f8;
        line-height: 35px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.8em;
        line-height: 35px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.8em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.8em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 35px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 1em;
        margin-left: 10%;
        margin-bottom: 135%;
    }
    #etps-p {
        font-size: 1em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 1em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 5%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 9.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: -0.30%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 11%;
    }
    /* Left */
    #realised {
        font-size: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 2rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 98px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 77%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 77%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 77%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 77%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.7%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 1rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 25px;
        background-position: 13px 1px;
        padding-left: 55px;
        display: block;
        text-align: left;
        width: 218px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 1em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.7em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 4%;
        margin-left: 4%;
        width: 35%;
    }
    #msg_bvn {
        margin-top: 9%;
        margin-left: 10%;
        color: black;
        font-size: 1.4em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_button {
        font-size: 1em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 45.5%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.3em;
    }
    #link_mdp {
        font-size: 1em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1366px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 16%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 1rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 5.4vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 6.3vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .7rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.1em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.1em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.7em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.6%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.6%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.5rem;
        height: 40px;
    }
    td {
        padding-top: 0.6%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.6%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.5rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.7em;
        color: green;
    }
    #cons {
        font-size: 0.7em;
        color: red;
    }
    #m_mdp {
        font-size: 0.7em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.7em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.7em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.7em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 0.9em;
        margin-left: 10%;
        margin-bottom: 130%;
    }
    #etps-p {
        font-size: 0.9em;
        margin-top: -28%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 0.9em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: -19%;
        margin-right: 8%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 11.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1.4%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 12%;
    }
    /* Left */
    #realised {
        font-size: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.8rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.8rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 1.7rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 96px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 76%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 76%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 76%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 76%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.9rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 22px;
        background-position: 13px 1px;
        padding-left: 50px;
        display: block;
        text-align: left;
        width: 190px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.6em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.6em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #pro {
        margin-top: 1%;
        margin-left: 4%;
        width: 30%;
    }
    #msg_bvn {
        margin-top: 2%;
        margin-left: 10%;
        color: black;
        font-size: 1.2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 30%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 30%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 30%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 30%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_button {
        font-size: 1em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 35%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.3em;
    }
    #link_mdp {
        font-size: 1em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1300px) and (max-height: 1024px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 22%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 0.8rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.5vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 6.3vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .7rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.1em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.1em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.7em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.5rem;
        height: 40px;
    }
    td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.5rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.7em;
        color: green;
    }
    #cons {
        font-size: 0.7em;
        color: red;
    }
    #m_mdp {
        font-size: 0.7em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.7em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.7em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.7em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 0.9em;
        margin-left: 10%;
        margin-bottom: 136%;
    }
    #etps-p {
        font-size: 0.9em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 0.9em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 7%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 10.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1.4%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 12%;
    }
    /* Left */
    #realised {
        font-size: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 15px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 10% 49%;
        background-size: 17px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 6vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 1.7rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 96px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 74%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 74%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.9rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 22px;
        background-position: 13px 1px;
        padding-left: 50px;
        display: block;
        text-align: left;
        width: 190px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #pro {
        margin-top: 1%;
        margin-left: 4%;
        width: 35%;
    }
    #msg_bvn {
        margin-top: 2%;
        margin-left: 10%;
        color: black;
        font-size: 1.2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_button {
        font-size: 1em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 61%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.3em;
    }
    #link_mdp {
        font-size: 1em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1300px) and (max-height: 860px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 22%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 0.8rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 3.8vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 4.5vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .7rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.1em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.1em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.7em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.5rem;
        height: 40px;
    }
    td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.5rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.7em;
        color: green;
    }
    #cons {
        font-size: 0.7em;
        color: red;
    }
    #m_mdp {
        font-size: 0.7em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.7em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.7em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.7em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 0.9em;
        margin-left: 10%;
        margin-bottom: 137%;
    }
    #etps-p {
        font-size: 0.9em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 0.9em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 7%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 10.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1.4%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 12%;
    }
    /* Left */
    #realised {
        font-size: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 16px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 16px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 6vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 1.7rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 95px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 74%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 74%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.9rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 22px;
        background-position: 13px 1px;
        padding-left: 50px;
        display: block;
        text-align: left;
        width: 190px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #pro {
        margin-top: 1%;
        margin-left: 4%;
        width: 35%;
    }
    #msg_bvn {
        margin-top: 2%;
        margin-left: 10%;
        color: black;
        font-size: 1.2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4.5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4.5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 1.1em;
        width: 35%;
    }
    #form_button {
        font-size: 1em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 1em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 57%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.3em;
    }
    #link_mdp {
        font-size: 1em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1300px) and (max-height: 800px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 18%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 0.8rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4.9vh;
        margin-left: -2vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 5.6vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 17px 20px;
        padding-left: 3.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 40px;
        cursor: pointer;
        font-size: .7rem;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 1.1em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 1.1em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 0.8%;
        position: absolute;
        display: block;
        margin-top: -0.8%;
        margin-left: 6%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 12px 12px;
        padding-left: 40px;
        border-radius: 20px;
        line-height: 30px;
        font-size: 0.7em;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 89%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 0.5rem;
        height: 40px;
    }
    td {
        padding-top: 0.7%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 0.7%;
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        height: 40px;
        background-color: #EEEEEE;
        font-size: 0.5rem;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.7em;
        color: green;
    }
    #cons {
        font-size: 0.7em;
        color: red;
    }
    #m_mdp {
        font-size: 0.7em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 13.4%;
        margin-top: 0.1%;
        cursor: pointer;
    }
    #pr,
    #prp,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.7em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 60%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 50px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.7em;
        background-color: #f5f6f8;
        line-height: 25px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
        margin-bottom: 3%;
    }
    #form_profile_mdp2 {
        font-size: 0.7em;
        line-height: 25px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.7em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.7em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 25px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 15%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -7%;
        font-size: 0.9em;
        margin-left: 10%;
        margin-bottom: 136%;
    }
    #etps-p {
        font-size: 0.9em;
        margin-top: -9%;
        margin-left: 10%;
    }
    #pmdp {
        font-size: 0.9em;
        margin-left: 10%;
        margin-top: -5%;
    }
    #pr9,
    #pr10 {
        margin-top: 4%;
        margin-bottom: 5%;
    }
    #pr9 {
        margin-top: 5%;
    }
    #pr5 {
        margin-top: 10%;
        margin-right: 7%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-right: 10.5%;
        margin-bottom: 4.5%;
    }
    #pr4 {
        margin-bottom: 4.5%;
        margin-left: 1.4%;
    }
    #pr {
        margin-top: 5%;
    }
    #pr2 {
        margin-left: 12%;
    }
    /* Left */
    #realised {
        font-size: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2vw;
    }
    #logo_devis {
        margin-bottom: -3.1%;
        margin-right: 11%;
        width: 12%;
    }
    #devis_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 15px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 9% 49%;
        background-size: 15px;
        line-height: 50px;
        padding-left: 29%;
        font-size: 0.7rem;
        width: 71%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 50px;
        padding-left: 8%;
        width: 91.5%;
        font-size: 0.7rem;
        color: #687484;
    }
    #left_suivi {
        width: 11%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    .header {
        position: absolute;
        text-align: center;
        color: white;
        font-size: 2em;
        height: 8vh;
        width: 100%;
        border-bottom: 2px solid #f5f6f8;
        box-sizing: border-box;
    }
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1%;
        margin-left: 14%;
        font-size: 1.7rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 11%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 3.8%;
        margin-left: 80%;
        width: 16.5%;
        height: 96px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 74%;
        text-align: left;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d {
        color: black;
        margin-top: -5%;
        width: 74%;
        background-color: white;
        font-size: 0.4em;
        line-height: 300%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -5%;
        width: 74%;
        background-color: #141B4D;
        font-size: 0.4em;
        line-height: 300%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 55px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.9rem;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 22px;
        background-position: 13px 1px;
        padding-left: 50px;
        display: block;
        text-align: left;
        width: 190px;
    }
    /* Accueil */
    #title_d {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
    }
    #title_c {
        margin-left: -5%;
        color: black;
        font-weight: bold;
        font-size: 0.9em;
        margin-top: -1%;
    }
    #par_d {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #par_c {
        margin-left: -5%;
        margin-top: -7%;
        font-size: 0.65em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 12%;
    }
    #pro {
        margin-top: 1%;
        margin-left: 4%;
        width: 35%;
    }
    #msg_bvn {
        margin-top: 2%;
        margin-left: 10%;
        color: black;
        font-size: 1.2em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #formulaire {
        margin-top: 42%;
        margin-left: 4%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4.5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4.5% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
}

@media only screen and (max-width: 1030px) {
    /* Chargement */
    #chargement {
        margin-left: 50%;
        margin-top: 18%;
    }
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 0.5rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 3.7vh;
        margin-left: -2.5vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 4.4vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 12px 16px;
        padding-left: 4.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 30px;
        cursor: pointer;
        font-size: 7px;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 0.7em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 0.7em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 1%;
        position: absolute;
        display: block;
        margin-top: -0.9%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 85%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 1%;
        position: absolute;
        display: block;
        margin-top: -0.9%;
        margin-left: 5.5%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 7px 7px;
        padding-left: 25px;
        border-radius: 20px;
        line-height: 20px;
        font-size: 7px;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 85%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 6px;
        height: 30px;
    }
    td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 5px;
    }
    th {
        height: 30px;
        background-color: #EEEEEE;
        font-size: 5px;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.6em;
        color: green;
    }
    #cons {
        font-size: 0.6em;
        color: red;
        margin-top: -5%;
    }
    #m_mdp {
        font-size: 0.6em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.8%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.8%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.5%;
        cursor: pointer;
    }
    #pr,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #prp,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.6em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 70%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 40px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 25%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 37%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.6em;
        background-color: #f5f6f8;
        line-height: 20px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.6em;
        line-height: 20px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_mdp2 {
        font-size: 0.6em;
        line-height: 20px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.6em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 20px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        font-size: 0.6em;
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 20px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 12%;
        font-weight: bold;
    }
    #profile3 {
        width: 12%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -10%;
        font-size: 0.7em;
        margin-left: 4%;
        margin-bottom: 166.5%;
    }
    #etps-p {
        font-size: 0.7em;
        margin-top: -9%;
        margin-left: 4%;
    }
    #pmdp {
        font-size: 0.7em;
        margin-left: 10%;
        margin-top: -7.5%;
    }
    #pr9,
    #pr10 {
        margin-top: -1%;
        margin-bottom: 3.5%;
    }
    #pr9 {
        margin-top: 4%;
    }
    #pr5 {
        margin-top: 2%;
        margin-right: 5%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-bottom: 4.5%;
    }
    #pr7 {
        margin-right: 11.5%;
    }
    #pr4 {
        margin-left: 6.5%;
    }
    #pr2 {
        margin-left: 12%;
    }
    #pr {
        display: block;
        margin-top: 4%;
    }
    /* Left */
    #realised {
        font-size: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1vw;
    }
    #logo_devis {
        margin-bottom: -4%;
        margin-right: 11%;
        width: 15%;
    }
    #devis_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 12.5% 49%;
        background-size: 15px 15px;
        line-height: 30px;
        padding-left: 33%;
        font-size: 0.7rem;
        width: 67%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 12.5% 49%;
        background-size: 15px 15px;
        line-height: 30px;
        padding-left: 33%;
        font-size: 0.7rem;
        width: 67%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.7rem;
        color: #687484;
    }
    #left_suivi {
        width: 15%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1.7%;
        margin-left: 17%;
        font-size: 1.3rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 15%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 4.3%;
        margin-left: 81%;
        width: 15.5%;
        height: 65px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 78%;
        background-color: white;
        font-size: 0.35em;
        line-height: 230%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 78%;
        text-align: left;
        font-size: 0.35em;
        line-height: 230%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_d {
        color: black;
        margin-top: -6%;
        width: 78%;
        background-color: white;
        font-size: 0.35em;
        line-height: 230%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -6%;
        width: 78%;
        background-color: #141B4D;
        font-size: 0.35em;
        line-height: 230%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 2.2%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.35em;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 16px;
        background-position: 13px 1px;
        padding-left: 40px;
        display: block;
        width: 14.3%;
        text-align: left;
    }
    /* Accueil */
    #title_d {
        margin-left: -25%;
        color: black;
        font-weight: bold;
        font-size: 0.7em;
    }
    #title_c {
        margin-left: -25%;
        color: black;
        font-weight: bold;
        font-size: 0.7em;
        margin-top: -2%;
    }
    #par_d {
        margin-left: -25%;
        margin-top: -7%;
        font-size: 0.6em;
        width: 100%;
    }
    #par_c {
        margin-left: -25%;
        margin-top: -7%;
        font-size: 0.6em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 0;
        margin-left: 4%;
        width: 38%;
    }
    #msg_bvn {
        margin-top: 0;
        margin-left: 10%;
        color: black;
        font-size: 1em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 35%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 35%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 4% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_button {
        font-size: 0.7em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 0.7em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 50%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.2em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.1em;
    }
    #link_mdp {
        font-size: 0.8em;
        margin-top: 3%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 800px) {
    /* Commande */
    .td_presta {
        padding-top: 1.8%;
        text-align: center;
        vertical-align: middle;
        line-height: 0.5rem;
        padding-bottom: 1.8%;
    }
    #table_presta:before {
        line-height: 2em;
        content: "-";
        color: white;
        /* to hide text */
        display: block;
    }
    .th_cmd {
        height: 30px;
        background-color: #EEEEEE;
    }
    #croix_cmd {
        float: right;
        width: 2%;
        cursor: pointer;
    }
    #croix_cmd2 {
        float: right;
        width: 7.5%;
        cursor: pointer;
    }
    #croix_cmd3 {
        position: absolute;
        margin-top: 4vh;
        margin-left: -2.5vw;
        width: 0.7%;
        cursor: pointer;
    }
    #croix_cmd4 {
        position: absolute;
        margin-top: 4.7vh;
        margin-left: -48vw;
        width: 0.5%;
        cursor: pointer;
    }
    #modalPresta3 {
        position: absolute;
        z-index: 2000;
        background-color: white;
        border: 1px solid #888;
        width: 20%;
        height: 28%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 6.5vh auto auto;
    }
    .prestaNum3 {
        background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
        background-position: 4% 45%;
        background-size: 12px 16px;
        padding-left: 4.5vw;
        margin-left: 1%;
        background-color: #fafafa;
        line-height: 30px;
        cursor: pointer;
        font-size: 7px;
    }
    #forget_title1 {
        padding: 20px;
        font-size: 0.7em;
        height: 7%;
        border-bottom: 2px solid #e8e8e8;
    }
    #forget_title3 {
        padding: 20px;
        margin-bottom: 3%;
        font-size: 0.7em;
        border-bottom: 2px solid #e8e8e8;
    }
    #eye_c {
        width: 1%;
        position: absolute;
        display: block;
        margin-top: -0.9%;
        margin-left: 6%;
    }
    .commande {
        float: right;
        vertical-align: top;
        color: black;
        width: 85%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    /* Devis */
    #eye_d {
        width: 1%;
        position: absolute;
        display: block;
        margin-top: -0.9%;
        margin-left: 5.5%;
    }
    #search {
        border: none;
        background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 5% 50%;
        background-size: 7px 7px;
        padding-left: 25px;
        border-radius: 20px;
        line-height: 15px;
        font-size: 5px;
        width: 20%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .devis {
        float: right;
        vertical-align: top;
        color: black;
        width: 85%;
        height: 100vh;
        padding-top: 8vh;
        box-sizing: border-box;
        overflow-wrap: normal;
        font-weight: 500;
    }
    #t1 td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 15px;
        padding-right: 15px;
    }
    table {
        margin-top: 2%;
        width: 95%;
        margin-left: 2%;
        font-size: 6px;
        height: 30px;
    }
    td {
        padding-top: 1%;
        text-align: center;
        vertical-align: middle;
        padding-bottom: 1%;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 4px;
    }
    th {
        height: 30px;
        background-color: #EEEEEE;
        font-size: 4px;
        padding: 0 8px 0 8px;
        font-weight: 500;
    }
    /* Profil */
    #p_mdp {
        font-size: 0.5em;
        color: green;
    }
    #cons {
        font-size: 0.5em;
        color: red;
        margin-top: -5%;
    }
    #m_mdp {
        font-size: 0.5em;
        color: red;
    }
    #img_mdp {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 1.1%;
        cursor: pointer;
    }
    #img_mdp2 {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 1.1%;
        cursor: pointer;
    }
    #img_mdpp {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.9%;
        cursor: pointer;
    }
    #img_mdp2p {
        position: absolute;
        width: 1.4%;
        margin-left: 14.4%;
        margin-top: 0.9%;
        cursor: pointer;
    }
    #pr,
    #pr1,
    #pr2,
    #pr3,
    #pr4,
    #pr5,
    #pr6,
    #pr7,
    #pr8,
    #prp,
    #pra,
    #pr9,
    #pr10 {
        font-size: 0.5em;
    }
    #pr8 {
        margin-left: 3%;
    }
    #form_profile_email {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 70%;
        overflow: hidden;
    }
    #form_profile_name {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_lname {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -2%;
        width: 30%;
        overflow: hidden;
    }
    #form_profile_pays_num {
        background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
        background-size: 18%;
        background-position: 10% 40%;
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 30px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_num {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 37%;
        overflow: hidden;
    }
    #form_profile_etps {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 30%;
        margin-right: 8%;
        overflow: hidden;
    }
    #form_profile_site {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 40%;
        overflow: hidden;
    }
    #form_profile_adr {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 50%;
        overflow: hidden;
    }
    #form_profile_postal {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        margin-right: 5%;
        overflow: hidden;
    }
    #form_profile_ville {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -3%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_pays {
        font-size: 0.5em;
        background-color: #f5f6f8;
        line-height: 15px;
        padding-left: 20px;
        border: 2px solid #eeeeee;
        margin-top: -1.1%;
        width: 20%;
        overflow: hidden;
    }
    #form_profile_mdp {
        font-size: 0.5em;
        line-height: 15px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_mdp2 {
        font-size: 0.5em;
        line-height: 15px;
        padding-left: 20px;
        border: 1px solid #eeeeee;
        margin-top: -5%;
        width: 50%;
    }
    #form_profile_btn {
        font-size: 0.5em;
        background-color: #0092ff;
        border-radius: 0.3em;
        line-height: 15px;
        color: white;
        border: none;
    }
    #form_profile_btn:hover {
        background-color: #0078d1;
        border-radius: 0.3em;
        line-height: 15px;
        color: white;
        border: none;
        cursor: pointer;
    }
    .form_profile {
        display: inline-block;
        height: 80vh;
        vertical-align: top;
    }
    #profile {
        width: 15%;
        font-weight: bold;
    }
    #profile3 {
        width: 11%;
        font-weight: bold;
        margin-left: 2%;
    }
    #info-p {
        margin-top: -10%;
        font-size: 0.7em;
        margin-left: 4%;
        margin-bottom: 155.5%;
    }
    #etps-p {
        font-size: 0.7em;
        margin-top: -9%;
        margin-left: 4%;
    }
    #pmdp {
        font-size: 0.7em;
        margin-left: 10%;
        margin-top: -11%;
    }
    #pr9,
    #pr10 {
        margin-top: -1%;
        margin-bottom: 3.5%;
    }
    #pr5 {
        margin-top: 2%;
        margin-right: 5%;
        margin-bottom: 5%;
    }
    #pra {
        margin-bottom: 3%;
    }
    #pr7,
    #pr8 {
        margin-bottom: 2%;
    }
    #pr7 {
        margin-right: 11.5%;
    }
    #pr4 {
        margin-left: 11%;
    }
    #pr2 {
        margin-left: 14%;
    }
    #pr {
        display: block;
        margin-top: -1%;
    }
    /* Left */
    #realised {
        font-size: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1vw;
    }
    #logo_devis {
        margin-bottom: -4%;
        margin-right: 11%;
        width: 15%;
    }
    #devis_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        color: #687484;
    }
    #devis_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        cursor: pointer;
        color: #333333;
    }
    #profil_p {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 12.5% 49%;
        background-size: 15px 15px;
        line-height: 30px;
        padding-left: 33%;
        font-size: 0.5rem;
        width: 67%;
        color: #687484;
    }
    #profil_p:hover {
        background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
        background-position: 12.5% 49%;
        background-size: 15px 15px;
        line-height: 30px;
        padding-left: 33%;
        font-size: 0.5rem;
        width: 67%;
        cursor: pointer;
        color: #333333;
    }
    #commande_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        color: #687484;
    }
    #commande_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        cursor: pointer;
        color: #333333;
    }
    #facture_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        color: #687484;
    }
    #facture_p:hover {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        color: #687484;
        cursor: pointer;
    }
    #reporting_p {
        line-height: 30px;
        padding-left: 11%;
        width: 89%;
        font-size: 0.5rem;
        color: #687484;
    }
    #left_suivi {
        width: 15%;
        background-color: #f5f6f8;
        height: 100vh;
        padding-top: 11vh;
        box-sizing: border-box;
        float: left;
    }
    /* Header */
    #proviso_title {
        position: absolute;
        color: black;
        margin-top: 1.5%;
        margin-left: 17%;
        font-size: 1.1rem;
    }
    #img_proviso {
        background-color: #141B4D;
        position: absolute;
        height: 100%;
        width: 15%;
        text-align: center;
        cursor: pointer;
    }
    .panelC {
        z-index: 5000000;
        position: absolute;
        margin-top: 4.7%;
        margin-left: 83%;
        width: 14.5%;
        height: 50px;
        box-shadow: 1px 1px 5px #555;
        background-color: white;
    }
    #panel_p {
        margin-top: 4%;
        color: black;
        width: 70%;
        background-color: white;
        font-size: 0.3em;
        line-height: 230%;
        text-align: left;
        background: url('../img/user.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_p:hover {
        margin-top: 4%;
        color: white;
        width: 70%;
        text-align: left;
        font-size: 0.3em;
        line-height: 230%;
        cursor: pointer;
        background: url('../img/user.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_d {
        color: black;
        margin-top: -10%;
        width: 70%;
        background-color: white;
        font-size: 0.3em;
        line-height: 230%;
        text-align: left;
        background: url('../img/exit.png') no-repeat;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #panel_d:hover {
        color: white;
        margin-top: -10%;
        width: 70%;
        background-color: #141B4D;
        font-size: 0.3em;
        line-height: 230%;
        cursor: pointer;
        text-align: left;
        background: url('../img/exit.png') no-repeat #141B4D;
        background-size: 6%;
        background-position: 5% 50%;
        padding-left: 35px;
    }
    #proviso_nom {
        position: absolute;
        color: black;
        margin-top: 1.8%;
        padding-top: 0.3%;
        margin-left: 80%;
        font-size: 0.3em;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        background: url('../img/persona.png') no-repeat;
        background-size: 15px;
        background-position: 13px 0px;
        padding-left: 40px;
        display: block;
        width: 12.8%;
        text-align: left;
    }
    /* Accueil */
    #title_d {
        margin-left: -30%;
        color: black;
        font-weight: bold;
        font-size: 0.5em;
    }
    #title_c {
        margin-left: -30%;
        color: black;
        font-weight: bold;
        font-size: 0.5em;
        margin-top: -2%;
    }
    #par_d {
        margin-left: -30%;
        margin-top: -7%;
        font-size: 0.4em;
        width: 100%;
    }
    #par_c {
        margin-left: -30%;
        margin-top: -7%;
        font-size: 0.4em;
        width: 100%;
    }
    #logo_hauts {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #logo_sipperec {
        vertical-align: top;
        margin-top: 5%;
        margin-left: 1%;
        width: 14%;
    }
    #pro {
        margin-top: 0;
        margin-left: 4%;
        width: 38%;
    }
    #msg_bvn {
        margin-top: 0;
        margin-left: 10%;
        color: black;
        font-size: 0.8em;
        width: 70%;
    }
    #c {
        margin-top: -10%;
        cursor: pointer;
        width: 25%;
        margin-left: 6%;
    }
    #r {
        margin-top: -10%;
        width: 25%;
        margin-left: 6%;
    }
    #f {
        margin-top: -10%;
        cursor: pointer;
        width: 25%;
        margin-left: 6%;
    }
    #d {
        cursor: pointer;
        width: 25%;
        margin-left: 6%;
    }
    /* Login */
    #form_email:hover {
        border: none;
        background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_email {
        border: none;
        background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 8.5% 60%;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_mdp {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 3% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_mdp:hover {
        margin-top: 3%;
        border: none;
        background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
        background-position: 3% 60%;
        background-size: 45px 57px;
        padding-left: 45px;
        border-radius: 20px;
        line-height: 35px;
        font-size: 0.8em;
        width: 35%;
    }
    #form_button {
        font-size: 0.7em;
        border: none;
        background-color: #141B4D;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
    }
    #form_button:hover {
        font-size: 0.7em;
        border: none;
        background-color: #6DD56B;
        color: white;
        border-radius: 20px;
        line-height: 40px;
        margin-top: 1%;
        width: 35%;
        cursor: pointer;
    }
    #formulaire {
        margin-top: 42%;
        margin-left: 4%;
    }
    #texte_accueil {
        color: white;
        font-size: 1.2em;
        font-weight: 600;
        width: 50%;
        margin-left: 24%;
    }
    h3 {
        color: #6D6D6D;
        font-size: 1.1em;
    }
    #link_mdp {
        font-size: 0.8em;
        margin-top: 3%;
        cursor: pointer;
    }
}