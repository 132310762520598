body {
    font-weight: 1em;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #4d4d4d;
    min-width: 800px;
}

h3 {
    color: #6D6D6D;
}

#page_connexion_adm {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(20, 27, 77, .5), rgba(20, 27, 77, 0.5)), url('../img/acc_adm.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

#design1 {
    width: 60%;
    height: 100vh;
    float: left;
}

#connexion {
    height: 100vh;
    width: 40%;
    background-color: white;
    float: left;
}

#formulaire {
    margin-top: 40%;
    margin-left: 4%;
}

h2 {
    margin-top: -20px;
    color: white;
    font-size: 4em;
    font-weight: bold;
}

#incorrect {
    color: #B22222;
    font-weight: bold;
    font-size: 0.8em;
}

#incorrect2 {
    color: #B22222;
    font-weight: bold;
    font-size: 0.8em;
}

#incorrect3 {
    color: #B22222;
    font-weight: bold;
    font-size: 0.8em;
}

#incorrect4 {
    color: #B22222;
    font-weight: bold;
    font-size: 0.8em;
}

#modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}


/* Modal Content/Box */

.modal-content {
    background-color: #EEEEEE;
    margin: 10% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 26%;
    /* Could be more or less, depending on screen size */
    height: 38%;
    border-radius: 4%;
    text-align: center;
}

#form_email:hover {
    border: none;
    background: url('../img/at_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
    background-position: 6.5% 60%;
    padding-left: 50px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 28%;
}

#form_email {
    border: none;
    background: url('../img/at_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
    background-position: 6.5% 60%;
    padding-left: 50px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 28%;
}

#form_mdp {
    margin-top: 3%;
    border: none;
    background: url('../img/cadenas_gris.svg') no-repeat scroll 7px 7px #EEEEEE;
    background-position: 2% 60%;
    background-size: 50px 65px;
    padding-left: 50px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 28%;
}

#form_mdp:hover {
    margin-top: 3%;
    border: none;
    background: url('../img/cadenas_vert.svg') no-repeat scroll 7px 7px #EEEEEE;
    background-position: 2% 60%;
    background-size: 50px 65px;
    padding-left: 50px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 28%;
}


/*#form_mdp{
	margin-top:3%;
	border: none;
	background: url('../img/cadenas_gris.svg') no-repeat  #EEEEEE;
	font-size: 1em;
	background-size:2.5em;
	background-position: 3% 60%;  
	padding-left:45px;
	border-radius: 20px;
	line-height: 40px;
	width:28%;
}

#form_mdp:focus{
	margin-top:3%;
	border: none;
	background: url('../img/cadenas_vert.svg') no-repeat #EEEEEE;
	font-size: 1em;
	background-size:2.2em;
	background-position: 5% 60%;  
	padding-left:40px;
	border-radius: 20px;
	line-height: 40px;
	width:28%;
}*/

#this_button {
    margin-top: 4%;
    display: block;
}

#form_button {
    font-size: 1em;
    border: none;
    background-color: #141B4D;
    color: white;
    border-radius: 20px;
    line-height: 40px;
    margin-top: 1%;
    width: 32%;
}

#form_button:hover {
    font-size: 1em;
    border: none;
    background-color: #6DD56B;
    color: white;
    border-radius: 20px;
    line-height: 40px;
    margin-top: 1%;
    width: 32%;
    cursor: pointer;
}

Link {
    font-size: 1em;
    border: none;
    background-color: #141B4D;
    color: white;
    border-radius: 20px;
    line-height: 34px;
    margin-top: 1%;
    width: 32%;
}

#link_mdp {
    margin-top: 3%;
    cursor: pointer;
}

a:link {
    text-decoration: none;
    color: #424242;
}

a:visited {
    color: #424242;
}

input:focus {
    outline: none;
}

button:focus {
    outline: none;
}

h3 {
    font-style: bold;
    font-size: 1.5em;
}

#forget_title {
    font-size: 1.2em;
    font-weight: bold;
    color: #141b4d;
    margin-top: 5%;
}

#form_button_forget {
    font-size: 1em;
    border: none;
    background-color: #6DD56B;
    color: white;
    border-radius: 20px;
    line-height: 40px;
    margin-top: 5%;
    width: 60%;
}

#form_button_forget:hover {
    font-size: 1em;
    border: none;
    background-color: #141B4D;
    color: white;
    border-radius: 20px;
    line-height: 40px;
    margin-top: 5%;
    width: 60%;
}

#form_mdp_forget {
    margin-top: 6%;
    border: none;
    font-size: 1em;
    background-size: 2.2em;
    background-position: 1% 60%;
    padding-left: 35px;
    border-radius: 20px;
    line-height: 40px;
    width: 68%;
}

#form_email_forget {
    border: none;
    margin-top: 15%;
    background-position: 3% 1%;
    padding-left: 35px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 68%;
}

#form_email_forget2 {
    border: none;
    margin-top: 5%;
    background-position: 3% 1%;
    padding-left: 35px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 68%;
}

#advice_forget {
    margin-top: 15%;
    font-size: 0.8em;
    text-align: justify;
}

#logo_proviso_adm {
    width: 40%;
}

#texte_accueil {
    color: white;
    font-size: 2em;
    font-weight: 600;
    width: 50%;
    margin-left: 24%;
}

#logo_cfi {
    margin-top: 4%;
    width: 24%;
}