#left_suivi {
    width: 11%;
    background-color: #f5f6f8;
    height: 100vh;
    padding-top: 11vh;
    box-sizing: border-box;
    float: left;
    text-align: left;
}

#logo_devis {
    margin-bottom: -2%;
    margin-right: 6%;
    width: 10%;
}

#devis_p {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    color: #687484;
}

#devis_p:hover {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
}

#profil_p {
    background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
    background-position: 12.5% 40%;
    background-size: 16px 16px;
    line-height: 50px;
    padding-left: 25.5%;
    font-size: 1rem;
    width: 74.5%;
    color: #687484;
}

#profile_p {
    line-height: 50px;
    padding-left: 8.5%;
    width: 91.5%;
    font-size: 1rem;
    color: #687484;
}

#profile_p:hover {
    line-height: 50px;
    padding-left: 8.5%;
    width: 91.5%;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
}

#profil_p:hover {
    background: url('../img/icon_p.png') no-repeat scroll 7px 7px;
    background-position: 12.5% 40%;
    background-size: 16px 16px;
    line-height: 50px;
    padding-left: 25.5%;
    font-size: 1rem;
    width: 74.5%;
    cursor: pointer;
    color: #333333;
}

#commande_p {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    color: #687484;
}

#commande_p:hover {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
}

#facture_p {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    color: #687484;
}

#facture_p:hover {
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    color: #687484;
    cursor: pointer;
}

#reporting_p {
    cursor: pointer;
    line-height: 50px;
    padding-left: 11%;
    width: 89%;
    font-size: 1rem;
    color: #687484;
}

#realised {
    font-size: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -1vw;
}

#logo_cfi_grey {
    width: 30%;
    margin-right: 1vw;
}

#logo_update {
    width: 30%;
    padding-bottom: 3vh;
    padding-left: 4.5vw;
}

#logo_update:hover {
    cursor: pointer;
}

#ver {
    position: absolute;
    bottom: 0;
    width: 11%;
}