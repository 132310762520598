#img_p{
	margin-left:5%;
	width:95%;
	height:70%;
}

.nom{
	display:inline-block;
}

.form_profile{
	display: inline-block;
  	height: 80vh;	
  	vertical-align:top;	
}

#profile{
	width: 18%;
	font-weight: bold;
}

#profile3{
	width: 11%;
	font-weight: bold;
}

#profile2,#profile4{
	width: 30%;
}

#pr,#pr1,#pr2, #pr3, #pr4, #pr5, #pr6, #pr7, #pr8, #pra, #pr9, #pr10{
	font-size:0.9em;
}

#form_profile_email{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-1.1%;
}

#form_profile_name{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-2%;
	width:20%;
	margin-right:5%;
}

#form_profile_lname{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-2%;
	width:20%;
}

#pr1{
	margin-right:18%;
}

#form_profile_pays_num{
	background: url('../img/france.jpg') no-repeat scroll 7px 7px #EEEEEE;
	background-size:18%;
	background-position: 10% 40%; 
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 60px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:20%;
	margin-right:5%;
}

#form_profile_num{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:30%;
}

#pr3{
	margin-right:31%;
}

#form_profile_etps{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:30%;
	margin-right:8%;
}

#form_profile_site{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:50%;
}


#pr5{
	margin-right:6%;
}


#form_profile_adr{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-1.1%;
	width:50%;
}

#pr7{
	margin-right:12%;
}


#form_profile_postal{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:20%;
	margin-right:5%;
}

#form_profile_ville{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-3%;
	width:20%;
}


#form_profile_pays{
	font-size:0.9em;
	background-color:#f5f6f8;
	line-height: 30px;
	padding-left: 20px;
	border: 2px  solid #eeeeee;
	margin-top:-1.1%;
	width:20%;
}

#info-p{
	margin-left:4%;
	margin-bottom: 94%;
}

#etps-p{
	margin-top:-9%;
	margin-left:4%;
}

#form_profile_mdp{
	font-size:0.9em;
	line-height: 30px;
	padding-left: 20px;
	border: 1px  solid #eeeeee;
	margin-top:-5%;
	width:50%;
}

#form_profile_mdp2{
	font-size:0.9em;
	line-height: 30px;
	padding-left: 20px;
	border: 1px  solid #eeeeee;
	margin-top:-5%;
	width:50%;
}

#form_profile_mdp22{

	font-size:0.9em;
	line-height: 35px;
	padding-left: 20px;
	border: 1px  solid #eeeeee;
	width:45%;
}

.p_info{
	display:inline-block;
	text-align: right;
	width:49%;
}


#pmdp{
	margin-left:10%;
}

#img_mdp{
	position:absolute;
	width:1.4%;
	margin-left: 13.4%;
	margin-top:0.2%;
	cursor:pointer;

}

#img_mdp2{
	position:absolute;
	width:1.4%;
	margin-left: 13.4%;
	margin-top:0.2%;
	cursor:pointer;

}

#img_mdpp{
	position:absolute;
	width:1.4%;
	margin-left: 13.4%;
	margin-top:-0.1%;
	cursor:pointer;

}

#img_mdp2p{
	position:absolute;
	width:1.4%;
	margin-left: 13.4%;
	margin-top:-0.1%;
	cursor:pointer;

}

#cons{
	font-size:0.9em;
	color:red;
}

#m_mdp{
	font-size:0.9em;
	color:red;
}

#p_mdp{
	font-size:0.9em;
	color:green;
}


#form_profile_btn{
	background-color:#0092ff;
	border-radius: 0.3em;
	line-height: 30px;
	color:white;
	border:none;
}

#form_profile_btn:hover{
	background-color:#0078d1;
	border-radius: 0.3em;
	line-height: 30px;
	color:white;
	border:none;
	cursor:pointer;
}