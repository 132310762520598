.devis {
    float: right;
    vertical-align: top;
    color: black;
    width: 89%;
    height: 100vh;
    padding-top: 8vh;
    box-sizing: border-box;
    overflow-wrap: normal;
    font-weight: 500;
}

td {
    padding-top: 0.8%;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0.8%;
    padding-left: 15px;
    padding-right: 15px;
}

tr {
    background-color: #fafafa;
    height: 15%;
}

#pagePD {
    cursor: pointer;
    margin-top: 1%;
    margin-left: 40%;
    width: 1%;
}

#pageSD {
    cursor: pointer;
    width: 1%;
    margin-top: 1%;
    margin-right: 53%;
}

#pagePD22 {
    cursor: pointer;
    margin-top: 1%;
    width: 1%;
}

#t2 {
    width: 50%;
    display: inline-table;
}

#pageSD22 {
    cursor: pointer;
    width: 1%;
    margin-top: 1%;
}

#graph {
    margin-top: 3vh;
    margin-left: 5vw;
}

#page_d {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#eye_d {
    width: 7%;
    margin-left: 1vw;
}

#fleche1 {
    width: 5%;
    margin-left: 0.4vw;
    text-align: center
}

#fleche2 {
    width: 7%;
    margin-left: 0.4vw;
    text-align: center
}