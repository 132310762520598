.commande {
    float: right;
    vertical-align: top;
    color: black;
    width: 89%;
    height: 100vh;
    padding-top: 8vh;
    box-sizing: border-box;
    overflow-wrap: normal;
    font-weight: 500;
}

.maj {
    float: right;
    vertical-align: top;
    color: black;
    width: 89%;
    height: 100vh;
    padding-top: 8vh;
    box-sizing: border-box;
    overflow-wrap: normal;
    font-weight: 500;
    padding-left: 5vw;
    padding-top: 5vw;
}

#eye_c {
    width: 6%;
    margin-left: 0.5vw;
}

#par_comm {
    overflow-wrap: normal;
    vertical-align: top;
    color: black;
}

table {
    margin-top: 2%;
    width: 95%;
    margin-left: 2%;
    font-size: 0.8rem;
    height: 20%;
}

th {
    height: 70px;
    background-color: #EEEEEE;
    font-size: 0.8rem;
    padding: 0 15px 0 15px;
    font-weight: 500;
}

.th_cmd {
    height: 30px;
    background-color: #EEEEEE;
}

#croix_cmd {
    float: right;
    width: 2%;
    cursor: pointer;
}

#croix_cmd2 {
    float: right;
    width: 7.5%;
    cursor: pointer;
}

#croix_cmd3 {
    position: absolute;
    margin-top: 5.2vh;
    margin-left: -2.5vw;
    width: 1%;
    cursor: pointer;
}

#croix_cmd4 {
    position: absolute;
    margin-top: 7vh;
    margin-left: -48vw;
    width: 0.5%;
    cursor: pointer;
}

tr:hover {
    padding-top: 0.8%;
    background-color: #e8e8e8;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0.8%;
}

.test:hover {
    padding-top: 0.8%;
    background-color: green;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0.8%;
    cursor: pointer;
}

.test2:hover {
    padding-top: 0.8%;
    background-color: lightgrey;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0.8%;
    cursor: pointer;
}

.cmd {
    cursor: pointer;
}

.td_presta {
    padding-top: 0.8%;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5rem;
    padding-bottom: 0.8%;
}

#charges {
    margin-left: 46%;
}

#forget_title1 {
    padding: 20px;
    font-size: 1.5em;
    height: 7%;
    border-bottom: 2px solid #e8e8e8;
}

#forget_title2 {
    margin-left: 37%;
    font-size: 1.5em;
}

#forget_title3 {
    padding: 20px;
    margin-bottom: 3%;
    font-size: 1.5em;
    border-bottom: 2px solid #e8e8e8;
}

#detail_content {
    margin-top: -3vh;
    overflow: auto;
    height: 75%;
}

#table_presta:before {
    line-height: 2em;
    content: "-";
    color: white;
    /* to hide text */
    display: block;
}

.prestaNum {
    margin-left: 1%;
    background-color: #f5f6f8;
    line-height: 30px;
    padding-left: 40px;
    border-radius: 2em;
}

#aucun_pv {
    margin-left: 1%;
    background-color: white;
    line-height: 30px;
    padding-left: 40px;
    border-radius: 2em;
}

.prestaNum2 {
    margin-left: 1%;
    background-color: white;
    line-height: 30px;
    padding-left: 40px;
    border-radius: 2em;
    cursor: pointer;
}

.prestaNum3 {
    background: url('../img/icon_pdf.png') no-repeat scroll 7px 7px;
    background-position: 4% 45%;
    background-size: 16px 21px;
    padding-left: 2.5vw;
    margin-left: 1%;
    background-color: #fafafa;
    line-height: 40px;
    cursor: pointer;
    font-size: 0.8rem;
}

#s_det {
    margin-left: 30%;
    font-size: 0.7em;
    cursor: pointer;
}

#desc_t {
    margin-top: 10%;
    margin-left: 37.5%;
}

#desc_txt {
    margin-top: 5%;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 3%;
    padding-bottom: 5%;
    background-color: #f5f6f8;
    border-radius: 2em;
    height: 35%;
    overflow-y: scroll;
}

#s_pv {
    margin-left: 10%;
    font-size: 0.7em;
    cursor: pointer;
}

#modalPresta1 {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    margin-left: -20%;
    width: 120vw;
    height: 100vh;
    overflow: none;
    background-color: rgb(20, 27, 77);
    background-color: rgba(20, 27, 77, 0.4);
}

#modalPresta2 {
    position: fixed;
    z-index: 2000;
    background-color: #EEEEEE;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 25%;
    height: 42%;
    border-radius: 4%;
    margin-top: -33%;
    margin-left: 45%;
}

#modalPresta3 {
    position: absolute;
    z-index: 2000;
    background-color: white;
    margin: 10% auto;
    border: 1px solid #888;
    width: 20%;
    height: 28%;
    margin-left: 65vw;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 8.5vh auto auto;
}

.modal-content1 {
    position: absolute;
    background-color: #f5f6f8;
    margin: 8% auto;
    /* X% from the top and centered */
    border: 1px solid #888;
    width: 57%;
    /* Could be more or less, depending on screen size */
    height: 42%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#presta {}

#pagePC {
    cursor: pointer;
    margin-top: 1%;
    margin-left: 50%;
    width: 1%;
}

#pageSC {
    cursor: pointer;
    width: 1%;
    margin-top: 1%;
    margin-right: 53%;
}

#page_c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#search {
    border: none;
    background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
    background-position: 5% 60%;
    background-size: 19px 19px;
    padding-left: 68px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 1em;
    width: 28%;
    margin-top: 2%;
    margin-left: 2%;
}