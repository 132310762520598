html,
body {
    margin: 0;
    padding: 0;
}

h1,
p {
    margin: 0 0 1em
}

#img_proviso {
    background-color: #141B4D;
    position: absolute;
    height: 100%;
    width: 11%;
    text-align: center;
    cursor: pointer;
}

.p_item {
    font-size: 1.3em;
}

#logo_p {
    width: 8%;
}

#txt_p {
    margin-top: -5%;
    margin-left: 18%;
}

#proviso_logo {
    position: absolute;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    margin-top: 6.5%;
}

#proviso_nom {
    position: absolute;
    color: black;
    margin-top: 0.5%;
    margin-left: 80%;
    font-size: 1rem;
    height: 100%;
    cursor: pointer;
}

#proviso_title {
    position: absolute;
    color: black;
    margin-top: 1.5%;
    margin-left: 13%;
    font-size: 2rem;
}

.header {
    position: absolute;
    text-align: center;
    color: white;
    font-size: 2em;
    height: 8vh;
    width: 100%;
    border-bottom: 2px solid #f5f6f8;
    box-sizing: border-box;
}

#logo_proviso_white {
    width: 90%;
}

#logo_retour {
    width: 15%;
    margin-right: 10%;
}

#logo_retour:hover {
    width: 15%;
    margin-right: 10%;
    cursor: pointer;
}

#logo_profil {
    width: 7%;
    margin-top: 5%;
    margin-right: 6%;
    display: block;
}

.panelC {
    position: absolute;
    margin-top: 3.7%;
    margin-left: 85%;
    width: 12%;
    height: 140%;
    box-shadow: 1px 1px 5px #555;
    background-color: white;
}

#panel_p {
    margin-top: 5.4%;
    color: black;
    width: 73%;
    background-color: white;
    font-size: 0.4em;
    line-height: 300%;
    text-align: left;
    background: url('../img/user.png') no-repeat;
    background-size: 6%;
    background-position: 5% 50%;
    padding-left: 50px;
}

#panel_p:hover {
    margin-top: 5.4%;
    color: white;
    width: 73%;
    text-align: left;
    font-size: 0.4em;
    line-height: 300%;
    cursor: pointer;
    background: url('../img/user.png') no-repeat #141B4D;
    background-size: 6%;
    background-position: 5% 50%;
    padding-left: 50px;
}

#panel_d {
    color: black;
    margin-top: -10%;
    width: 73%;
    background-color: white;
    font-size: 0.4em;
    line-height: 300%;
    text-align: left;
    background: url('../img/exit.png') no-repeat;
    background-size: 6%;
    background-position: 5% 50%;
    padding-left: 50px;
}

#panel_d:hover {
    color: white;
    margin-top: -10%;
    width: 73%;
    background-color: #141B4D;
    font-size: 0.4em;
    line-height: 300%;
    cursor: pointer;
    text-align: left;
    background: url('../img/exit.png') no-repeat #141B4D;
    background-size: 6%;
    background-position: 5% 50%;
    padding-left: 50px;
}