#pagePD1{
	cursor: pointer;
	margin-top:1%;
	margin-left:45%;
	width:1%;
}

#pageSD1{
	cursor:pointer;
	width:1%;
	margin-top:1%;
	margin-right:45%;
}

#pagePD2{
	cursor: pointer;
	margin-top:1%;
	margin-left:45%;
	width:1%;
}

#pageSD2{
	cursor:pointer;
	width:1%;
	margin-top:1%;
	margin-right:48%;
}

#p_all_users{
	width:40%;
	height:5%;
	text-align: center;
	border: 0.8rem solid;
	padding-top: 1.3%;
	margin: auto;
	margin-bottom: 1%;
	cursor: pointer;
	display:inline-block;
	padding-bottom:1.3%;
}

#p_all_logos{
	width:15%;
	height:5%;
	text-align: center;
	border: 0.8rem solid;
	padding-top: 1.3%;
	margin: auto;
	margin-bottom: 1%;
	cursor: pointer;
	display:inline-block;
	padding-bottom:1.3%;
}

#search1{
	border: none;
	background: url('../img/loupe.png') no-repeat scroll 7px 7px #EEEEEE;
	background-position: 5% 60%; 
	background-size: 19px 19px;
	padding-left:68px;
	border-radius: 20px;
	line-height: 40px;
	font-size: 1em;
	width:28%;
	margin-top:2%;
	margin-bottom: 5%;
	margin-left:2%;
}

#sp{
	width:3%;
	margin-left: 1%;
	vertical-align:middle;
  	display: inline-block;
  	cursor: pointer;
}

.form_container{
	
}
#div_form{ 
	display:inline-block;
}
